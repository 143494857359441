import React from 'react'
import {graphql} from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import Preorder from '../components/preorder'
import Video from '../components/video/video'

import Layout from '../containers/layout'

export const query = graphql`
  query LandingPageQuery {
    landingPage: sanityLandingPage(_id: {regex: "/(drafts.|)landingPage/"}) {
      hideLandingPage
      youtubeEmbedUrl
      preorderButtonCaption
      preorderButtonText
      preorderButtonUrl
    }
  }
`

const LandingPage = ({ data: { landingPage }, errors }) => {
  if (errors) return <Layout><GraphQLErrorList errors={errors} /></Layout>
  if (!landingPage) throw new Error(`Missing "Landing". Open the studio at http://localhost:3333`)

  return (
    landingPage.hideLandingPage
      ? <Layout />
      : <Layout>
          <Video src={landingPage.youtubeEmbedUrl} />
          <Preorder
            preorderButtonCaption={landingPage.preorderButtonCaption}
            preorderButtonUrl={landingPage.preorderButtonUrl}
            preorderButtonText={landingPage.preorderButtonText}
          />
        </Layout>
  )
}

export default LandingPage
