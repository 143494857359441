import React from 'react'

import Button from '../button'

import styles from './preorder.module.css'
import {paragraph} from '../typography.module.css'
import {cn} from '../../lib/helpers'

const Preorder = ({ preorderButtonCaption, preorderButtonUrl, preorderButtonText }) => (
  <div className={styles.wrapper}>
    <div className={styles.preorder}>
      <p className={cn(paragraph, styles.text)}>{preorderButtonCaption}</p>
      <Button text={preorderButtonText} url={preorderButtonUrl} />
    </div>
  </div>
)

export default Preorder
