import {Link} from 'gatsby'
import React, {useContext} from 'react'

import styles from './button.module.css'

import {AppContext} from '../../contexts/app-context'

const Button = ({ text = '', to, url }) => {
  const {buttonColor} = useContext(AppContext)

  return (
    to
      ? <button className={styles.button} style={{ backgroundColor: buttonColor }}>
          <Link to={to}>{text}</Link>
        </button>
      : <button className={styles.button} style={{ backgroundColor: buttonColor }}>
          <a href={url} target='_blank'>{text}</a>
        </button>
  )
}

export default Button
